<template>
  <div class="row justify-content-center">
    <div
      class="card custom-card gutter-b mt-5 p-2 col-12"
      style="border-radius: 10px; position: sticky; top: 16%; z-index: 50"
    >
      <div class="d-flex align-items-center">
        <b-input-group>
          <b-form-input
            id="input-live"
            v-model="search"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Cari Kegiatan Bimbingan Teknis"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-secondary bg-secondary">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <div
      class="card custom-card gutter-b p-2 col-12"
      style="border-radius: 20px; background-color: #ebedf3"
      v-for="(data, i) in filterKegiatan"
      :key="i"
    >
      <b-card style="border-radius: 20px">
        <div class="row">
          <div class="col-lg-6 col-12">
            <h3>
              {{ data.nama_kegiatan }}
            </h3>
          </div>
          <div class="col-lg-6 col-12">
            <b-card-text class="float-right">
              <strong>
                <span style="color: red"> * </span>
                <span
                  ><i class="fa fa-calendar pr-2" aria-hidden="true"></i
                ></span>
                {{
                  format_tanggal(data.tanggal_mulai, "D MMM YYYY") +
                  " sampai dengan " +
                  format_tanggal(data.tanggal_selesai, "D MMM YYYY")
                }}
              </strong>
            </b-card-text>
          </div>
        </div>

        <hr class="my-5" />

        <b-card-text>
          <div class="mt-5">
            Materi:
            <ul class="ml-5 mr-5">
              <li v-for="(row, i) in data.materi" :key="i">
                {{ row.judul }}
              </li>
            </ul>
          </div></b-card-text
        >
        <b-button
          @click="handleLihatKegiatanClick(data.id)"
          class="float-right"
          style="color: #ebedf3; background-color: #3699ff"
        >
          Lihat Kegiatan
        </b-button>
      </b-card>
    </div>

    <div
      v-if="filterKegiatan.length === 0"
      style="text-align: center; background-color: #ebedf3; padding: 200px"
    >
      <img style="width: 30%" src="/media/logos/logo-notfound.png" alt="" />
      <p class="mt-5">Tidak ada hasil yang ditemukan.</p>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import format_date from "@/core/utils/helper";
import Swal from "sweetalert2";

export default {
  name: "pilihKegiatan",
  components: { format_date },
  mounted() {
    this.loadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kegiatan" }]);
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      kegiatan: [],
      daftarPeserta: [],
      search: "",
    };
  },
  computed: {
    filterKegiatan() {
      return this.kegiatan.filter((post) => {
        return post.nama_kegiatan
          .toLowerCase()
          .trim()
          .includes(this.search.toLowerCase().trim());
      });
    },
  },
  methods: {
    format_tanggal(value, format) {
      return format_date(value, format);
    },
    loadData() {
      this.axios
        .get(
          this.url +
            "/jadwal_kegiatan?filter=id_ptsp,like,%|" +
            this.user.id_ptsp +
            "|%",
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kegiatan = response.data.data;
        });
      this.axios
        .get(this.url + "/jadwal_peserta?filter=id_user,=," + this.user.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.daftarPeserta = response.data.data;
        });
    },
    handleLihatKegiatanClick(id) {
      const peserta = this.daftarPeserta.find((p) => p.id_kegiatan === id);
      this.$router.push("/kegiatan-bimtek/" + id);
      // if (
      //   peserta &&
      //   (peserta.status_verifikasi === "Menunggu Verifikasi" ||
      //     peserta.status_verifikasi === null)
      // ) {
      //   Swal.fire({
      //     title: "Menunggu Verifikasi",
      //     text: 'Status verifikasi peserta masih dalam status "Menunggu Verifikasi".',
      //     icon: "info",
      //     confirmButtonText: "OK",
      //   });
      // } else {
      //   this.$router.push("/kegiatan-bimtek/" + id);
      // }
    },
  },
};
</script>
